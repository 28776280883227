<template>
    <div>
        <CRow class="p-3">
        <CCol>
          <CCard>
            <CCardHeader>
                <div class="row">
                    <div class="col-md-6">
                        <CIcon name="cil-justify-center"/><strong> Deposit List</strong>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <b-overlay :show='loading'>
                    <div class="overflow-auto">
                        <b-table thead-class="bg-light text-dark" emptyText="Data Not Found" show-empty bordered hover :items="itemList" :fields="fields">
                            <template v-slot:cell(index)="data">
                                {{ data.index }}
                            </template>
                            <template v-slot:cell(status)="data">
                                <span class="badge badge-success" v-if="data.item.status == 1">Active</span>
                                <span class="badge badge-danger" v-else>Inactive</span>
                            </template>
                            <template v-slot:cell(name)="data">
                                <span>{{ data.item.user.name }}</span>
                            </template>
                            <template v-slot:cell(category_name)="data">
                                <span>{{ data.item.category.name }}</span>
                            </template>
                        </b-table>
                    </div>
                </b-overlay>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <b-modal id="modal-1"
          size="lg"
        header-bg-variant="primary"
        header-text-variant="light"
          title="Entry/Edit" hide-footer>
        <div>
            <Form :id='editId'/>
      </div>
      </b-modal>
    </div>
    </template>
    <script>
    import RestApi, { baseUrl } from '../../../config/api_config'
    import Form from './Form'
    import iziToast from 'izitoast';
    
    export default {
        components: {
            Form
        },
        created () {
            this.loadData ()
        },
        data() {
          return {
            baseUrl: baseUrl,
            search: {
                name: ''
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            editId: ''
          }
        },
        computed: {
            itemList () {
                return this.$store.state.list
            },
            fields () {
                const labels = [
                    { label: 'Sl No', class: 'text-center' },
                    { label: 'Name', class: 'text-left' },
                    { label: 'Cat. Name', class: 'text-left' },
                    { label: 'Pay Date', class: 'text-left' },
                    { label: 'Amount', class: 'text-left' },
                ]
    
                let keys = []
                keys = [
                { key: 'id' },
                { key: 'name' },
                { key: 'category_name' },
                { key: 'pay_date' },
                { key: 'amount' },
                ]
                return labels.map((item, index) => {
                    return Object.assign(item, keys[index])
                })
            },
            loading () {
              return this.$store.state.static.loading
            },
            listReload () {
              return this.$store.state.static.listReload
            }
        },
        watch: {
          listReload: function (newVal) {
            if (newVal) {
                this.loadData()
            }
          }
        },
        methods: {
            changeStatus (item, status) {
                this.$swal({
                    title: 'Are you sure to change status ?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.toggleStatus(item, status)
                    }
                })
            },
            toggleStatus (item, statusId) {
                RestApi.putData(baseUrl, `api/quiz-sub-categories/status/${item.id}`, { status: statusId }).then(response => {
                    if (response.success) {
                        this.$store.dispatch('mutedLoad', { listReload: true })
                        iziToast.success({
                            title: 'Success',
                            message: response.message
                        })
                    } else {
                        iziToast.error({
                            title: 'Success',
                            message: response.message
                        })
                    }
                })
            },
    
            deleteItem (item) {
                this.$swal({
                    title: 'Are you sure to delete this row ?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteData(item)
                    }
                })
            },
            deleteData (item) {
                RestApi.deleteData(baseUrl, `api/quiz-sub-categories/delete/${item.id}`).then(response => {
                    if (response.success) {
                        this.$store.dispatch('mutedLoad', { listReload: true })
                        iziToast.success({
                            title: 'Success',
                            message: response.message
                        })
                    } else {
                        iziToast.error({
                            title: 'Success',
                            message: response.message
                        })
                    }
                })
            },
            edit (item) {
                this.editId = item.id
            },
            searchData () {
                this.loadData()
            },
            loadData () {
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, category_id: this.$route.query.id })
                this.$store.dispatch('mutedLoad', { loading: true})
                RestApi.getData(baseUrl, 'api/quiz-sub-categories/list', params).then(response => {
                    if (response.success) {
                        this.$store.dispatch('setList', response.data.data)
                        this.paginationData(response.data)
                    }
                    this.$store.dispatch('mutedLoad', { loading: false })
                })
            },
            paginationData (data) {
                this.pagination.perPage = parseInt(data.per_page)
                this.pagination.currentPage = parseInt(data.current_page)
                this.pagination.total = parseInt(data.total)
            }
        },
        filters: {
            subStr: function(string) {
                return string.substring(0, 80) + '...';
            }
        }
    }
    </script>